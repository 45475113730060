import cn from "classnames"
import { Link as GatsbyLink } from "gatsby"
import noop from "lodash/noop"
import React, { FC, MouseEvent, ReactNode } from "react"

type LinkType = "btn-primary" | "link-footer"

const cssClassMap: Record<LinkType, string> = {
  "btn-primary": "button is-primary",
  "link-footer": "link is-small-text is-dark",
}

export interface LinkProps {
  className?: string
  linkType?: LinkType
  onClick?: (event: MouseEvent) => void
  to: string
  tabIndex?: number
  children?: ReactNode
}

const getClassName = (buttonType?: LinkType, className?: string): string =>
  cn(buttonType && cssClassMap[buttonType], className)

const Link: FC<LinkProps> = ({ className, linkType, onClick = noop, ...props }) => (
  <GatsbyLink onClick={onClick} className={getClassName(linkType, className)} {...props} />
)

export default Link
