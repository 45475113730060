exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-index-tsx": () => import("./../../../src/templates/about/index.tsx" /* webpackChunkName: "component---src-templates-about-index-tsx" */),
  "component---src-templates-accessibility-index-tsx": () => import("./../../../src/templates/accessibility/index.tsx" /* webpackChunkName: "component---src-templates-accessibility-index-tsx" */),
  "component---src-templates-author-details-index-tsx": () => import("./../../../src/templates/author-details/index.tsx" /* webpackChunkName: "component---src-templates-author-details-index-tsx" */),
  "component---src-templates-authors-index-tsx": () => import("./../../../src/templates/authors/index.tsx" /* webpackChunkName: "component---src-templates-authors-index-tsx" */),
  "component---src-templates-contact-index-tsx": () => import("./../../../src/templates/contact/index.tsx" /* webpackChunkName: "component---src-templates-contact-index-tsx" */),
  "component---src-templates-contact-success-index-tsx": () => import("./../../../src/templates/contact-success/index.tsx" /* webpackChunkName: "component---src-templates-contact-success-index-tsx" */),
  "component---src-templates-contribute-index-tsx": () => import("./../../../src/templates/contribute/index.tsx" /* webpackChunkName: "component---src-templates-contribute-index-tsx" */),
  "component---src-templates-contribute-success-index-tsx": () => import("./../../../src/templates/contribute-success/index.tsx" /* webpackChunkName: "component---src-templates-contribute-success-index-tsx" */),
  "component---src-templates-imprint-index-tsx": () => import("./../../../src/templates/imprint/index.tsx" /* webpackChunkName: "component---src-templates-imprint-index-tsx" */),
  "component---src-templates-license-permission-index-tsx": () => import("./../../../src/templates/license-permission/index.tsx" /* webpackChunkName: "component---src-templates-license-permission-index-tsx" */),
  "component---src-templates-media-details-index-tsx": () => import("./../../../src/templates/media-details/index.tsx" /* webpackChunkName: "component---src-templates-media-details-index-tsx" */),
  "component---src-templates-medias-collection-index-tsx": () => import("./../../../src/templates/medias-collection/index.tsx" /* webpackChunkName: "component---src-templates-medias-collection-index-tsx" */),
  "component---src-templates-medias-index-tsx": () => import("./../../../src/templates/medias/index.tsx" /* webpackChunkName: "component---src-templates-medias-index-tsx" */),
  "component---src-templates-module-details-index-tsx": () => import("./../../../src/templates/module-details/index.tsx" /* webpackChunkName: "component---src-templates-module-details-index-tsx" */),
  "component---src-templates-modules-index-tsx": () => import("./../../../src/templates/modules/index.tsx" /* webpackChunkName: "component---src-templates-modules-index-tsx" */),
  "component---src-templates-offers-index-tsx": () => import("./../../../src/templates/offers/index.tsx" /* webpackChunkName: "component---src-templates-offers-index-tsx" */),
  "component---src-templates-plain-language-index-tsx": () => import("./../../../src/templates/plain-language/index.tsx" /* webpackChunkName: "component---src-templates-plain-language-index-tsx" */),
  "component---src-templates-privacy-index-tsx": () => import("./../../../src/templates/privacy/index.tsx" /* webpackChunkName: "component---src-templates-privacy-index-tsx" */),
  "component---src-templates-search-index-tsx": () => import("./../../../src/templates/search/index.tsx" /* webpackChunkName: "component---src-templates-search-index-tsx" */),
  "component---src-templates-sign-language-index-tsx": () => import("./../../../src/templates/sign-language/index.tsx" /* webpackChunkName: "component---src-templates-sign-language-index-tsx" */),
  "component---src-templates-start-index-tsx": () => import("./../../../src/templates/start/index.tsx" /* webpackChunkName: "component---src-templates-start-index-tsx" */),
  "component---src-templates-terms-index-tsx": () => import("./../../../src/templates/terms/index.tsx" /* webpackChunkName: "component---src-templates-terms-index-tsx" */),
  "slice---src-components-layout-backdrop-index-ts": () => import("./../../../src/components/layout/backdrop/index.ts" /* webpackChunkName: "slice---src-components-layout-backdrop-index-ts" */),
  "slice---src-components-layout-footer-index-ts": () => import("./../../../src/components/layout/footer/index.ts" /* webpackChunkName: "slice---src-components-layout-footer-index-ts" */)
}

