import cn from "classnames"
import React, { FC } from "react"

import ButtonSearch from "../../button-search"
import { IconType } from "../../svg-icon/svg-icon"
import NavLink, { INavLink } from "../header/navbar-link"
import NavbarToggleDesktopView from "../header/navbar-toggle-desktop"

import { cssContainerLayout } from "../../../templates/start/start.module.scss"
import * as styles from "./navbar-menu.module.scss"

interface INavbarMenu {
  mediaFormLinkSearch: INavLink
  mediaFormLink: INavLink
  navLinks: INavLink[]
  toggleSearchBar(): void
  toggleMainMenu(): void
  isMainMenuVisible: boolean
}

const NavbarMenu: FC<INavbarMenu> = ({
  navLinks,
  toggleSearchBar,
  toggleMainMenu,
  isMainMenuVisible,
  mediaFormLink,
}) => {
  const materialsCollectionNavLink = navLinks.find((navLink) => navLink.type === "media_collection")
  const navLinksWithMediaFormLink = [...navLinks, mediaFormLink]

  return (
    <div className={cn("navbar-menu", styles.component)}>
      <div>
        <div className={cn("navbar-end", styles.navbarEnd)}>
          {materialsCollectionNavLink && (
            <NavLink
              label={materialsCollectionNavLink.label}
              slug={materialsCollectionNavLink.slug}
              iconType={IconType.X}
              tabIndex={1}
              isMaterialsLink={true}
            />
          )}
          <ButtonSearch className="button-search" toggleSearchBar={toggleSearchBar} />
          <NavbarToggleDesktopView isMainMenuVisible={isMainMenuVisible} toggleMainMenu={toggleMainMenu} />
        </div>

        <div className={styles.mainMenuContainer}>
          <div className={isMainMenuVisible ? styles.open : styles.closed}>
            <ul className={cn(cssContainerLayout, styles.mainMenuItem)}>
              {navLinksWithMediaFormLink.map((navLink, idx) =>
                navLink.type !== "sign_language" &&
                navLink.type !== "plain_language" &&
                navLink.type !== "accessibility" &&
                navLink.type !== "media_collection" ? (
                  <NavLink key={idx} {...navLink} tabIndex={1} onClick={toggleMainMenu} />
                ) : null
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarMenu
