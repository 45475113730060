import React, { FC } from "react"

import BurgerIcon from "../../../../images/icons/burger.inline.svg"
import CrossIcon from "../../../../images/icons/cross.inline.svg"

import * as styles from "./navbar-toggle-desktop.module.scss"

interface INavbarToggleViewProps {
  isMainMenuVisible: boolean
  toggleMainMenu: () => void
}

const NavbarToggleDesktopView: FC<INavbarToggleViewProps> = ({ isMainMenuVisible, toggleMainMenu }) => (
  <button
    className={styles.component}
    aria-label="main-menu"
    aria-expanded={isMainMenuVisible ? "true" : "false"}
    onClick={toggleMainMenu}
    tabIndex={1}
  >
    {isMainMenuVisible ? <CrossIcon className={styles.crossIcon} /> : <BurgerIcon className={styles.burgerIcon} />}
  </button>
)

export default NavbarToggleDesktopView
