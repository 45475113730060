import cn from "classnames"
import { Link as GatsbyLink } from "gatsby"
import noop from "lodash/noop"
import React, { FC } from "react"
import { useSelector } from "react-redux"

import { selectMediaCollection } from "../../../../state/media-collection/media-collection.selectors"
import Badge from "../../../badge"
import SvgIcon from "../../../svg-icon/svg-icon"
import INavbarLink from "./navbar-link.view.props"

import { cssComponent, cssIconLeft, cssIconLeftMini, cssIsBrand, cssIsHighlighted } from "./navbar-link.module.scss"

const NavLink: FC<INavbarLink> = ({
  iconType,
  isHighlighted,
  isBrand,
  label,
  onClick = noop,
  slug,
  tabIndex,
  isMaterialsLink,
  classNames,
}) => {
  const mediaCollection = useSelector(selectMediaCollection)
  return (
    <GatsbyLink
      to={slug}
      className={cn("navbar-item", cssComponent, isBrand && cssIsBrand, isHighlighted && cssIsHighlighted, classNames)}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {iconType && <SvgIcon type={iconType} className={cn(cssIconLeft, isMaterialsLink && cssIconLeftMini)} />}
      {label}
      {isMaterialsLink ? <Badge label={`${mediaCollection.length}`} /> : null}
    </GatsbyLink>
  )
}

export default NavLink
