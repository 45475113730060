import cn from "classnames"
import React, { FC, MouseEventHandler } from "react"

export enum IconType {
  ARROW_UP = "icon-arrow-up",
  ARROW_DOWN = "icon-arrow-down",
  ARROW_RIGHT = "icon-arrow-right",
  CROSS = "icon-cross",
  X = "icon-x",
  DOWNLOAD = "icon-download",
  EXTERNAL = "icon-external",
  UPLOAD = "icon-upload",
  SEARCH = "icon-search",
}

interface ISvgIcon {
  className?: string
  size?: number
  type: IconType
  onClick?: MouseEventHandler<HTMLElement>
}

const getStyle = (size?: number) => {
  if (!size) {
    return
  }
  return { fontSize: `${size}px` }
}

const SvgIcon: FC<ISvgIcon> = ({ className, size, type, ...props }) => (
  <i className={cn(type, className)} style={getStyle(size)} {...props} />
)

export default SvgIcon
