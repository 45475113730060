import cn from "classnames"
import React, { FC } from "react"

import CrossIcon from "../../images/icons/cross.inline.svg"
import SearchInput from "../search-input"
import { ISearchBar } from "./search-bar-menu.props"

import { cssNavbar } from "../layout/header/header.module.scss"
import * as styles from "./search-bar-menu.module.scss"

const SearchBarMenu: FC<ISearchBar> = ({ toggleSearchBar, isSearchBarVisible }) => {
  const onCloseSearchInput = () => {
    toggleSearchBar()
  }

  return (
    <div className={cn("search-bar-container", styles.searchBarContainer)}>
      <div
        className={cn(
          cssNavbar,
          isSearchBarVisible ? "search-bar-container-transition" : "none search-bar-container-transition"
        )}
      >
        <div className="close-button-container">
          <button className="close-button" id="close" onClick={toggleSearchBar}>
            <CrossIcon className={styles.crossIcon} />
          </button>
        </div>
        <SearchInput
          onClickSuggestion={onCloseSearchInput}
          inputID={"search-bar-input-menu-header"}
          inputLinkID={"search-link-header"}
        />
      </div>
    </div>
  )
}

export default SearchBarMenu
