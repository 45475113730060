import cn from "classnames"
import { navigate } from "gatsby"
import noop from "lodash/noop"
import React, { FC, useState, useEffect, useRef } from "react"

import useDebounce from "../../hooks/use-debounce"
import { queryAPISuggestions } from "../../utils/queryAPISuggestions"
import { ISearchInputProps, ISuggestion } from "./search-input.props"

import * as styles from "./search-input.module.scss"

const SearchInput: FC<ISearchInputProps> = ({
  query = "",
  skipInitialSuggestionRequest = false,
  onClickSuggestion = noop,
  inputID,
  inputLinkID,
  category = null,
  order = null,
}) => {
  const [inputValue, setInputValue] = useState<string>(query)
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([])
  const [skipSuggestionRequest, setSkipSuggestionRequest] = useState<boolean>(skipInitialSuggestionRequest)
  const debouncedValue = useDebounce<string>(inputValue, 350)

  //create ref on specific input element
  const containerRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    // If initial inputValue (=query) is passed down,
    // make sure that its value becomes part of local state,
    // especially when it has changed after first render
    if (query && query !== "") {
      setInputValue(query)
    }
  }, [query])

  useEffect(() => {
    if (inputValue !== "") {
      if (!skipSuggestionRequest) {
        queryAPISuggestions(inputValue, setSuggestions)
      }

      setSkipSuggestionRequest(false)
    } else {
      setSuggestions([])
    }
  }, [debouncedValue])

  const onChangeHandler = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInputValue(event.target.value)
  }

  const getSearchUrlForQuery = (query: string) =>
    `/suche?query=${encodeURI(query)}${category ? `&category=${encodeURI(category)}` : ""}${
      order ? `&order=${encodeURI(order)}` : ""
    }`

  const onClickSuggestionHandler = (ev: any) => {
    // Use navigate() to avoid re-load of page

    ev.preventDefault()
    const target = ev.target as HTMLElement
    navigate(target.getAttribute("href") || "")
    setSuggestions([])
    onClickSuggestion()
    setSkipSuggestionRequest(true)
  }

  const onSubmitHandler = (ev: any) => {
    ev.preventDefault()
    navigate(getSearchUrlForQuery(inputValue))
    setSuggestions([])
    onClickSuggestion()
    setSkipSuggestionRequest(true)
  }

  //Handle input search / user suggestion selection via keyboard keys
  useEffect(() => {
    /**
     * @todo To have this automatically scoped without relying on *.closest or inputID, use onKeyDown event of form-element.
     * Then use event.target.querySelector(...) instead of document.querySelector(...)
     * @todo Add support for spacebar
     */

    // The initial variable determins weather the user used the arrow keys to navigate between suggestion
    let initial = false

    // The suggestionPoints points to the currently selected suggestion
    let suggestionPointer = 0

    const keyDownHandler = (event: KeyboardEvent) => {
      if (!event.target || !(event.target instanceof HTMLElement)) {
        return
      }

      if (!containerRef.current?.contains(event.target)) {
        // Verlassen, wenn das Event außerhalb des Suchleisten-Containers ausgelöst wurde
        return
      }

      if (event.key === "Enter") {
        // Submit search query
        event.preventDefault()
        let input: any = document.getElementById(inputID)

        // Check whether arrow keys have been click and if therefor a suggestion is selected
        if (initial) {
          let selectedSuggestionListElement: any =
            document.querySelectorAll(".search-input-suggestions li")[suggestionPointer]
          selectedSuggestionListElement.querySelector("a").click()
        } else {
          // If no suggestion is selected simply run search of user input
          if (input.value !== "") {
            let inputLink = document.getElementById(inputLinkID)
            if (inputLink) inputLink.click()
          }
        }
      } else if (event.key === "ArrowDown") {
        // Submit search query
        event.preventDefault()

        // Check is suggestions exist
        if (suggestions.length > 0) {
          // Determine weather arrow down key has been pressed
          if (!initial) {
            initial = true

            let x: any = document.querySelectorAll(".search-input-suggestions li")[suggestionPointer]
            x.querySelector("a").focus()
          } else {
            // Move target by increasing the point to run list downwars
            if (suggestionPointer < suggestions.length - 1) {
              suggestionPointer += 1
              let selectedSuggestionListElement: any =
                document.querySelectorAll(".search-input-suggestions li")[suggestionPointer]
              selectedSuggestionListElement.querySelector("a").focus()
            }
          }
        }
      } else if (event.key === "ArrowUp") {
        // Submit search query
        event.preventDefault()

        // Check if suggestions exist
        if (suggestions.length > 0) {
          // Move target by decreasing the point to run list upwards
          if (suggestionPointer > 0) {
            suggestionPointer -= 1
            let selectedSuggestionListElement: any =
              document.querySelectorAll(".search-input-suggestions li")[suggestionPointer]
            selectedSuggestionListElement.querySelector("a").focus()
          }
        }
      }
    }

    const containerEl = containerRef.current
    if (containerEl) {
      containerEl.addEventListener("keydown", keyDownHandler)
    }

    // Cleanup-Funktion
    return () => {
      if (containerEl) {
        containerEl.removeEventListener("keydown", keyDownHandler)
      }
    }
  }, [inputID, inputLinkID, suggestions])

  return (
    <form method="GET" action="/suche" onSubmit={onSubmitHandler} ref={containerRef}>
      <div className="search-bar-input-container">
        <div className="search-input">
          <input
            className="input"
            autoFocus={true}
            type="text"
            id={inputID}
            placeholder="Suchbegriff eingeben"
            value={inputValue}
            onChange={onChangeHandler}
            name="query"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
          />
        </div>
      </div>
      <div className="search-bar-input-container">
        <div className="search-input-button">
          <input type="submit" className={cn("button is-primary", styles.searchBtn)} id={inputLinkID} value="Suchen" />
        </div>
      </div>

      {suggestions.length > 0 && inputValue.length >= 3 ? (
        <div className="search-bar-input-container">
          <ul className="search-input-suggestions">
            {suggestions.map((suggestion, suggestionIndex) => (
              <li key={suggestionIndex}>
                {/** Don`t use GatsbyLink here */}
                <a href={getSearchUrlForQuery(suggestion.value)} onClick={onClickSuggestionHandler}>
                  <i className="icon-arrow-right" />
                  {suggestion.value}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </form>
  )
}

export default SearchInput
