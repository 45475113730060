import React, { FC } from "react"
import Badge from "../badge.view"
import * as styles from "./media-badge.module.scss"
import {Link as GatsbyLink} from "gatsby-link";

export interface IMediaBadge {
  count: number,
  onClick: () => void,
  collectionLink: string
}

const MediaBadgeView: FC<IMediaBadge> = ({ count, onClick, collectionLink }) => {
  return count > 0 ? (
    <div className={styles.component}>
      <GatsbyLink
        to={collectionLink}
        onClick={onClick}
        tabIndex={1}
      >
        <Badge label={`${count}`} onClick={() => onClick()} />
      </GatsbyLink>
    </div>
  ) : null
}

export default MediaBadgeView
