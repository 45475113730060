import cn from "classnames"
import React, { FC } from "react"

import SearchIcon from "../../images/icons/lupe.inline.svg"

import { cssComponent, buttonSearch } from "./button-search.module.scss"

export interface IButtonLoadMoreView {
  toggleSearchBar(): void
  className?: string
}

const ButtonSearch: FC<IButtonLoadMoreView> = ({ className = "", toggleSearchBar }) => {
  return (
    <button className={cn(className, cssComponent, buttonSearch)} onClick={toggleSearchBar} tabIndex={1}>
      <div className="search-icon">
        <SearchIcon alt="Suchlupe" aria-hidden="true" />
      </div>
      <span>Suche</span>
    </button>
  )
}

export default ButtonSearch
