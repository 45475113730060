import cn from "classnames"
import React, { FC } from "react"

import BurgerIcon from "../../../../images/icons/burger.inline.svg"
import CrossIcon from "../../../../images/icons/cross.inline.svg"

import * as styles from "./navbar-toggle.module.scss"

interface INavbarToggleViewProps {
  isOpen: boolean
  onToggle: () => void
}

const NavbarToggleAsideView: FC<INavbarToggleViewProps> = ({ isOpen, onToggle }) => (
  <a
    role="button"
    className={cn(styles.component, isOpen && "is-active")}
    aria-label="menu"
    aria-expanded={isOpen ? "true" : "false"}
    onClick={() => onToggle()}
  >
    {isOpen ? <CrossIcon className={styles.crossIcon} /> : <BurgerIcon className={styles.burgerIcon} />}
  </a>
)

export default NavbarToggleAsideView
